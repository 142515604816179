import React from "react";
import { useTranslation } from "react-i18next";
import { EmailTemplate } from "app-types/template";
import TemplateGrid from "components/ui/base/TemplateGrid";
import { EMAIL_TEMPLATES_PATH } from "constants/paths";
import TemplateCard from "components/ui/base/TemplateGrid/TemplateCard";
import { getEmailTemplatePagePath } from "utils/path";

interface EmailTemplatesProps {
  templates: EmailTemplate[];
  showMore?: boolean;
}

const EmailTemplates = ({ templates, showMore }: EmailTemplatesProps) => {
  const { t } = useTranslation();

  return (
    <TemplateGrid
      alternativeColors
      id={"email-templates"}
      title={t("components.ui.extended.EmailTemplates.title")}
      description={t("components.ui.extended.EmailTemplates.description", {
        count: templates.length,
      })}
      data={templates}
      component={(emailTemplate) => (
        <TemplateCard
          id={`email-template-${emailTemplate.community_email_template_id}`}
          path={getEmailTemplatePagePath(
            emailTemplate.community_email_template_id,
            emailTemplate.name,
            emailTemplate.author.username
          )}
          backgroundImageURL={emailTemplate.thumbnail_url}
          title={emailTemplate.name}
          description={emailTemplate.description}
        />
      )}
      showMore={
        showMore
          ? {
              title: t("components.ui.extended.EmailTemplates.showMore.title"),
              description: t(
                "components.ui.extended.EmailTemplates.showMore.description"
              ),
              button: t(
                "components.ui.extended.EmailTemplates.showMore.button"
              ),
              path: `${EMAIL_TEMPLATES_PATH}/`,
            }
          : undefined
      }
    />
  );
};

export default EmailTemplates;
