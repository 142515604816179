import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { EmailTemplate } from "app-types/template";
import PlainHeader from "components/ui/base/PlainHeader";
import { EMAIL_TEMPLATES_PATH } from "constants/paths";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import Customers from "components/ui/extended/Customers";
import RegisterForEmailMarketing from "components/ui/extended/RegisterForEmailMarketing";
import EmailTemplates from "components/ui/extended/EmailTemplates";
import BlogPostsBox from "components/ui/base/BlogPostsBox";

interface SuccessStoryPageProps {
  data: {
    allEmailsTemplates: {
      nodes: EmailTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: SuccessStoryPageProps) => {
  const { t } = useTranslation();

  const emailTemplates = data.allEmailsTemplates.nodes;

  const totalTemplates = emailTemplates.length;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.templates-for-email-marketing.seo.title")}
        description={t("pages.templates-for-email-marketing.seo.description")}
        image={{
          relativePath: "meta/community-templates-for-saas.jpg",
          alt: t("pages.templates-for-email-marketing.seo.title"),
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(
                  `pages.templates-for-email-marketing.faq.${index}.question`
                ),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(
                    `pages.templates-for-email-marketing.faq.${index}.answer`
                  ),
                },
              };
            }),
          },
        ]}
      />
      <PlainHeader
        title={t("pages.templates-for-email-marketing.title")}
        description={t("pages.templates-for-email-marketing.description", {
          count: totalTemplates,
        })}
        maxWidth={900}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Templates",
            path: "/templates/",
          },
          {
            name: "Email Marketing",
            path: `${EMAIL_TEMPLATES_PATH}/`,
          },
        ]}
      />

      <EmailTemplates templates={emailTemplates} />

      <QuestionsAndAnswers
        title={t("pages.templates-for-email-marketing.faq.title")}
        description={t("pages.templates-for-email-marketing.faq.description")}
        elements={[1, 2, 3].map((index: number) => {
          return {
            question: t(
              `pages.templates-for-email-marketing.faq.${index}.question`
            ),
            answer: t(
              `pages.templates-for-email-marketing.faq.${index}.answer`
            ),
          };
        })}
      />

      <RegisterForEmailMarketing />

      <Customers />

      <BlogPostsBox
        category={"email-marketing"}
        title={"Email Marketing Articles"}
        maxPosts={3}
        postsPerRow={3}
        currentPostSlug={"/"}
      />
    </GlobalWrapper>
  );
};
export const templatesPageQuery = graphql`
  query {
    allEmailsTemplates {
      nodes {
        id
        name
        subject
        thumbnail_url
        description
        created_at
        community_email_template_id
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
